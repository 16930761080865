// extracted by mini-css-extract-plugin
export var big = "Button-module--big--8f743";
export var buttonContainer = "Button-module--buttonContainer--c846a";
export var dark = "Button-module--dark--78b50";
export var green = "Button-module--green--1a91f";
export var inherite = "Button-module--inherite--917c6";
export var light = "Button-module--light--7398e";
export var noHover = "Button-module--noHover--cd895";
export var red = "Button-module--red--6257e";
export var slim = "Button-module--slim--873ef";
export var white = "Button-module--white--8e2a4";